import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"400px"}},[_c('h4',{staticClass:"text-center text-h4 text-lg-h3 mt-4"},[_vm._v(" Sign In Or Sign Up ")]),_c('p',{staticClass:"text--secondary text-h6 mb-n2 mt-4 text-center"},[_vm._v(" Create an account and pay your bills instantly with MTN Mobile Money or Orange Money ")])]),(_vm.isWebView)?_c(VAlert,{staticClass:"mt-4",attrs:{"color":"info","outlined":"","text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" If you have any trouble authenticating, please download the NyangaPay web app from the "),_c('b',[_vm._v("safari")]),_vm._v(" browser. ")]),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"block":"","exact":"","color":"secondary","to":{
                                name: _vm.$constants.ROUTE_NAMES.INSTALL_SAFARI,
                            }},on:{"click":_vm.installOnSafariClicked}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiDownload))]),_vm._v(" Install on Safari ")],1)],1)],1)],1):_vm._e()],1)],1),_c(VRow,[_c('social-sign-in-buttons',{staticStyle:{"width":"360px","max-width":"90%"},attrs:{"after-auth-path":_vm.afterAuthPath},on:{"update:afterAuthPath":function($event){_vm.afterAuthPath=$event},"update:after-auth-path":function($event){_vm.afterAuthPath=$event}}})],1),_c(VRow,{class:{ 'mb-8': _vm.isWebView }},[_c('back-button',{staticClass:"mx-auto"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }